import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardVerticalComponent } from './card-vertical/card-vertical.component';
import { MaterialModules } from '../core/material';
import { CardHorizontalComponent } from './card-horizontal/card-horizontal.component';
import { RouterModule } from '@angular/router';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { MobileScreenComponent } from './mobile-screen/mobile-screen.component';
import { LoaderComponent } from './loader/loader.component';

@NgModule({
  declarations: [CardVerticalComponent, CardHorizontalComponent, MobileScreenComponent, LoaderComponent],
  imports: [
    CommonModule,
    MaterialModules,
    RouterModule,
    TranslateModule.forChild({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      },
      isolate: true
  }),
  ],
  exports: [CardVerticalComponent, CardHorizontalComponent,MobileScreenComponent,LoaderComponent]
})
export class CommonItemsModule {
  constructor(private translate: TranslateService){
    translate.use(localStorage.getItem('currentlang') || 'en')  }

}
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
   return new TranslateHttpLoader(http, '/../assets/i18n/', '.json');
}
