export const environment = {
    production: true,
    environmentName: 'stage',
 
  apiUrl: 'https://staging-app.areyou.in:7578/v1/',  //stag
  apiBaseUrl: 'https://staging-app.areyou.in:7578/v1/venue/',  //stag
  assetsUrl: 'https://staging-app.areyou.in/venue/', //stag
    mapUrl: 'http://apina.address.gov.sa/NationalAddress/v3.1/Address/',
    mapKey: '2a98820f2785459da430cabad27e3274',
    googleKey: 'AIzaSyBFhZRrwNvqyTdefgyzLZMwzxxUO7eDNRA'
  };