<mat-card [ngClass]="{'requested-card': borderShow=='true'}">
    <div class="card-banner">
        <img src="{{imgName}}">
        <div class="card-tint"><img src="assets/Images/card-tint.png"></div>
        <div>
            <div>
                <label class="arcade">{{label}}</label>
            </div>
            <p>{{cardTitle}}</p>
        </div>
    </div>
    <div class="mat-inner">
        <div class="detail">
            <img src="assets/Images/single-user.svg">
            <span>{{ userName }}</span>
        </div>
        <div class="detail">
            <img src="assets/Images/sport-type.svg">
            <span>{{sportType}}</span>
        </div>
        <div class="detail">
            <img src="assets/Images/tour-schedule.svg">
            <span>{{schedule | date: 'EE, d MMM y, HH:mm'}} <span *ngIf="duration">- {{duration | date: 'hh:mm a'}}</span> </span>
        </div>
        <div class="view-more cursor-pointer" [routerLink]="['/non-partnered-event-bookings']" *ngIf="viewRequest">
            <span class="view-req">{{'common.viewRequest' | translate}}
                <img src="assets/Images/view-arrow-red.svg">
            </span>
        </div>
        <div class="view-more cursor-pointer" [routerLink]="['/non-partnered-event-bookings']" *ngIf="viewMore">
            <span class="view-mr">{{'common.viewMore' | translate}}
                <img src="assets/Images/view-arrow.svg">
            </span>
        </div>
    </div>
</mat-card>