<div class="cards mb-4" [ngClass]="customClass" (click)="openVenue()">
    <div class="imgLeft"><img src="{{imgLeft}}"></div>
    <div class="d-flex card-inner">
        <h4>{{title}}</h4>
        <h5>{{sportType}} <span *ngIf="sportLengthShow"> + {{sportLength}}</span></h5> 
        <div class="card-stats" *ngIf="showDetail">
            <p><img src="assets/Images/user-count.svg"><span>&nbsp;{{capacity}}</span></p>
            <p><img src="assets/Images/price-tag.svg"><span>&nbsp; {{priceRange}}</span></p>
        </div>
        <div class="view-more cursor-pointer" [routerLink]="['/'+routes.venues]" *ngIf="showVenueProfile">
            <span class="view-mr">{{'common.venueProfile' | translate}}
                <img src="assets/Images/view-arrow.svg">
            </span>
        </div>
    </div>
    <div class="notify">{{'common.one' | translate}}</div>
</div>