import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private translate: TranslateService,
    private router: Router,
    ) {
    translate.setDefaultLang('en');
    router.events.subscribe((val) => {
      console.log(val);
      this.hrefUrl();
    });
    
}
  title = 'AreYouIN';
   currentlang:any
   href:any='';
   hrefArr:any=['/termAndCondition','/privacyPolicy','/dataDeletion']
  langval:boolean;
  ngOnInit(): void {
    this.currentlang=localStorage.getItem('currentlang')||'en';
    this.langvalue()
  }
  hrefUrl(){
    this.href=localStorage.getItem('href');
  }
  langvalue(){
    if(this.currentlang!='ar'){
      this.langval=false;
    }
    else{
      this.langval=true;
    }
  }
  @HostListener("window:beforeunload",["$event"])
    clearLocalStorage(event){
      if(localStorage.getItem('rememberMe') == 'false'){
        // localStorage.clear();
      }
    }
}
