import { NgModule } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatStepperModule } from '@angular/material/stepper';
import { MatChipsModule } from '@angular/material/chips';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatRadioModule} from '@angular/material/radio';

@NgModule({
    exports: [
        MatInputModule, MatIconModule, MatButtonModule, MatFormFieldModule,
        MatCheckboxModule, MatSelectModule, MatExpansionModule, MatSlideToggleModule,
        MatCardModule, MatDividerModule, MatDatepickerModule, MatNativeDateModule,
        MatTooltipModule, MatSnackBarModule, MatSortModule, MatTableModule,
        MatMenuModule, MatPaginatorModule, MatDialogModule, MatTabsModule,
        MatButtonToggleModule, NgxMatSelectSearchModule, MatAutocompleteModule, MatStepperModule,
        MatChipsModule,MatProgressBarModule,MatRadioModule
    ]
})

export class MaterialModules { }