import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-screen',
  templateUrl: './mobile-screen.component.html',
  styleUrls: ['./mobile-screen.component.scss']
})
export class MobileScreenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
