import { Component, Input, OnInit } from '@angular/core';
import { RouteConstant } from 'src/app/core/routes';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card-horizontal',
  templateUrl: './card-horizontal.component.html',
  styleUrls: ['./card-horizontal.component.scss']
})
export class CardHorizontalComponent implements OnInit {

  @Input() customClass = "";
  @Input() imgLeft = "";
  @Input() title = "Card Title";
  @Input() sportType = "Sport Name";
  @Input() capacity = "Capacity";
  @Input() priceRange ;

  @Input() showDetail: boolean = false;
  @Input() showVenueProfile: boolean = false;
  @Input() sportLength;
  @Input() id;
  sportLengthShow: boolean = false;
  routes = RouteConstant;

  constructor(
    public router:Router
  ) { }
  ngOnInit(): void {
    console.log(this.priceRange)
    var price=this.priceRange.split('/')[0]+' '+'SR'
    this.priceRange=price+'/'+this.priceRange.split('/')[1]
    if(this.sportLength>0)
      this.sportLengthShow = true;
  }
  openVenue(){
    console.log(this.id);
    if(this.id!=undefined){
      localStorage.setItem('facId',this.id)
      this.router.navigate(['/facilities'])
    }
  }

}
