import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-vertical',
  templateUrl: './card-vertical.component.html',
  styleUrls: ['./card-vertical.component.scss']
})
export class CardVerticalComponent implements OnInit {

  @Input() imgName = "assets/Images/sample.jpg";
  @Input() label = "Arcade";
  @Input() cardTitle = "Otherworld Theatre's Virtual Gala";
  @Input() userName = "George Orwell";
  @Input() sportType = "Arcade";
  @Input() schedule;
  @Input() duration;
  @Input() viewRequest: boolean = false;
  @Input() viewMore: boolean = false;
  @Input() borderShow: string;

  constructor() { }

  ngOnInit(): void {
    
    if(this.userName.includes('undefined')){
      this.userName = 'NA'
    }
  }

  
}
