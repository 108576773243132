export const RouteConstant = {
  // Auth
  login: 'login',
  signup: 'signup',
  forgetPassword: 'forgot-password',
  resetPassword: 'reset-password',
  staffSetPassword: 'staff-reset-password',
  paypalResponse:'paypalResponse',
  sendFeedback:'sendFeedback',
  // Pages
  dashboard: 'dashboard',

  bookings: 'bookings',

  nonPartneredEventBookings: 'non-partnered-event-bookings',

  facilities: 'facilities',
  facilitiesAdd: 'facilities/add',
  facilitiesEdit: 'facilities/edit',

  venues: 'venues',

  tournaments: 'tournaments',
  tournamentsAdd: 'tournaments/create',
  tournamentsEdit: 'tournaments/edit',

  events: 'events',
  eventsCreate: 'events/create',
  eventsEdit: 'events/edit',

  publicProfile: 'public-profile',
  publicProfileAdd: 'public-profile/add',
  publicProfileEdit: 'public-profile/edit',
  
  contact: 'contacts',

  users: 'users',

  usermessages: 'user-messages',

  staff: 'staff',
  statistics: 'statistics',
  settings: 'settings',

  coin: 'coin',

  referees: 'referees',
  dispute: 'dispute',
  report: 'reporting-event',

  customeCreate: 'bookings/create',
  customeEdit: 'bookings/edit',
  staffRoute:'staffModule',

  tournamentsStaff: 'staffModule/tournaments',
  tournamentsAddStaff: 'staffModule/tournaments/create',
  tournamentsEditStaff: 'staffModule/tournaments/edit',

  staffCustomeCreate: 'staffModule/bookings/create',
  staffCustomeEdit: 'staffModule/bookings/edit',
  staffbooking: 'staffModule/bookings',

  staffEventCreate: 'staffModule/events/create'
}
