<section>
    <div class="accounttype d-flex justify-content-center align-items-center bg-graphic">

        <div  class="card text-center">
            <div class="top-logo"><img src="assets/Images/logo-top.svg"></div>
            <h4>{{'auth.welcomeTo' | translate}}</h4>
            <h2 class="d-flex justify-content-center align-items-end"><span> {{'auth.areYou' | translate}} </span><img src="assets/Images/logo-text.svg" width="60px" height="60px"> ?</h2>
            <!-- <h4>{{'auth.chooseYourAccType' | translate}}</h4> -->
            <p class="mb-2">{{'auth.Unfortunately' | translate}}</p>
            <p>{{'auth.accessing' | translate}}</p>
        </div>

    </div>
</section>
