import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router'
import { map, catchError} from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { RouteConstant } from 'src/app/core/routes';
import { environment as ENV } from '../../environments/environment';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {

    constructor(
        private router: Router
    ){ }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const token: string = localStorage.getItem('accessToken');
        const userType:string = localStorage.getItem('userType')
        let language= localStorage.getItem('currentlang')||'en';
        if(!request.headers.has('locale')){
            request= request.clone({headers: request.headers.set('locale',language)})
        }
        if(request.url.includes('apina.address.gov.sa')){
                request = request.clone({ headers: request.headers.set('api_key', ENV.mapKey) });
        }
        else{
            if (token) {
                request = request.clone({ headers: request.headers.set('Authorization', token) });
            }
            if (timezone) {
                request = request.clone({ headers: request.headers.set('timezone', timezone) });
            }
            if(userType=='company'){
                if (!request.headers.has('Content-Type') && (request.url!=ENV.apiBaseUrl+'uploadImage' && request.url!=ENV.apiUrl+'company/uploadCSVCompanyEmployee')){
                    request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
                }
            }
            else{
                if (!request.headers.has('Content-Type') && (request.url!=ENV.apiBaseUrl+'uploadImage' && request.url!=ENV.apiBaseUrl+'uploadCSVContact')){
                    request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
                }
            }
        }
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log('event--->>>', event);
                }
                return event;
            }),
            catchError((error: any) => {
                // console.log("Error", error)
                if (error && error.status && error.status === 401) {
                    localStorage.clear();
                    this.router.navigate(['/' + RouteConstant.login]);
                }
                return throwError(error);
            }));
        }
}